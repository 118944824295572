@tailwind base;
@tailwind components;
@tailwind utilities;

/* Import custom fonts */
@import './font.css';

@import 'slick-carousel/slick/slick.css';
@import 'slick-carousel/slick/slick-theme.css';

@layer base {
  body {
    @apply font-jetbrains;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    @apply font-joystix;
  }

  h1 {
    @apply text-[32px] lg:text-[48px] 2xl:text-[78px] leading-none tracking-normal;
  }

  h2 {
    @apply 2xl:text-[48px] text-[32px] leading-none tracking-normal;
  }

  h3 {
    @apply 2xl:text-[32px] text-[22px] leading-none tracking-normal;
  }

  h4 {
    @apply 2xl:text-[22px] text-[18px] leading-none tracking-normal;
  }

  h5 {
    @apply text-base leading-none tracking-normal 2xl:text-lg;
  }
}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .dot-container {
    position: relative;
  }

  .filled-dot {
    display: none;
  }

  .slick-active .filled-dot {
    display: block;
  }

  .slick-active .empty-dot {
    display: none;
  }
}

/* Smooth transitions for hover effect */
.logo-default {
  transition: opacity 0.3s ease-in-out;
}

.logo-hover {
  transition: opacity 0.3s ease-in-out;
}

/* On hover, hide the default logo and show the hover logo */
.relative:hover .logo-default {
  opacity: 0;
}

.relative:hover .logo-hover {
  opacity: 1;
}
.slick-arrow {
  z-index: 30 !important;
}

.scrollbar-dark::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #1c1c1c;
}

.scrollbar-dark::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

.scrollbar-dark::-webkit-scrollbar-thumb {
  background-color: #000000;
  border: 2px solid #555555;
  border-radius: 10px;
}

.you {
  border: 1.5px solid #b73fff;
  background: rgba(183, 63, 255, 0.16);
}


.slick-dots {
  bottom: 12px !important;
  line-height: 0px !important;

  @media (min-width: 1024px) {
    bottom: 41.5px !important;
  }
}
