@font-face {
    font-family: 'Joystix';
    src: url('./assets/fonts/Joystix/joystix.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'JetBrains Mono';
    src: url('./assets/fonts/JetBrainsMono/JetBrainsMono-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'JetBrains Mono';
    src: url('./assets/fonts/JetBrainsMono/JetBrainsMono-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'JetBrains Mono';
    src: url('./assets/fonts/JetBrainsMono/JetBrainsMono-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
  }